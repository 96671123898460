export default {
  data() {
    return {
      book_id: '',
      quiz_id: '',
      quiz: null,
      quizLoading: false,
    };
  },

  computed: {
    quizAsArray() {
      return this.quiz ? [this.quiz] : [];
    },
  },

  methods: {
    getQuiz() {
      if (this.quiz_id && !this.quiz) {
        this.quizLoading = true;
        this.$store.dispatch('quiz/getQuizzById', { bookId: this.book_id, quizId: this.quiz_id }).then((quiz) => {
          this.quiz = quiz;
        }).catch(() => {}).finally(() => {
          this.quizLoading = false;
        });
      }
    },
  },

  created() {
    this.$nextTick(() => { this.getQuiz(); });
  },
};
